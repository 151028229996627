<template>
  <div>
    <project-layout
      :project="project"
      :project-full="projectFull"
      @expanded="onProjectPanelExpanded"
    >
      <template v-slot:actions>
        <project-actions
          :project="projectFull"
          :on-assign-manager="onProjectAssignManager"
          :on-delete="onProjectDelete"
          :on-cancel="onProjectCancel"
          :on-export="onProjectExport"
          :on-resume="onProjectResume"
          :on-suspend="onProjectSuspend"
          :on-repair-project="onProjectRepair"
        >
          <template v-slot:actions>
            <!-- Select project in project module (tab) -->
            <base-menu-item
              v-if="visibleJumpToProject"
              :to="projectRouteLocation(projectId)"
              :disabled="!canNavigateToProject(projectId)"
            >
              <v-icon :color="menuItemColorClass">
                {{ commandNavigateToProjectIconName }}
              </v-icon>
              <template v-slot:tooltipContent>
                <div>
                  {{ tooltipNavigateToProjectLabel(projectName) }}
                </div>
              </template>
              {{ commandNavigateToProjectLabel(projectName) }}
            </base-menu-item>
          </template>
        </project-actions>
      </template>

      <template v-slot:alert>
        <base-alert
          v-model="showAlert"
          :dismissible="isAlertDismissible"
          :type="currentAlertType"
        >
          <span v-html="alertMessage"></span>
        </base-alert>
      </template>
    </project-layout>

    <!-- Delete current project dialog -->
    <project-operation-dialog
      :project="project"
      :project-operation="projectOperation.DeleteProject"
      :visible="visibleDialogDeleteProject"
      :perform-action="performProjectDelete"
      @close="closeDialogDeleteProject"
    ></project-operation-dialog>

    <!-- Cancel current project dialog -->
    <project-operation-dialog
      :project="project"
      :project-operation="projectOperation.CancelProject"
      :visible="visibleDialogCancelProject"
      :perform-action="performProjectCancel"
      @close="closeDialogCancelProject"
    ></project-operation-dialog>

    <!-- Suspend current project dialog -->
    <project-operation-dialog
      :project="project"
      :project-operation="projectOperation.SuspendProject"
      :visible="visibleDialogSuspendProject"
      :perform-action="performProjectSuspend"
      @close="closeDialogSuspendProject"
    ></project-operation-dialog>

    <!-- Resume current project dialog -->
    <project-operation-dialog
      :project="project"
      :project-operation="projectOperation.ResumeProject"
      :visible="visibleDialogResumeProject"
      :perform-action="performProjectResume"
      @close="closeDialogResumeProject"
    ></project-operation-dialog>

    <!-- Show Report Dialog -->
    <project-detailed-report-dialog
      :project="projectFull"
      :visible="visibleDialogProjectExport"
      @close="closeDialogExportProject"
    ></project-detailed-report-dialog>

    <!-- Assign Manager Dialog -->
    <assign-manager-dialog
      :project="projectFull"
      :visible="visibleDialogAssignProject"
      :assign-event="onManagerAssignment"
      @close="closeDialogAssignManager"
    ></assign-manager-dialog>

    <snackbar
      v-model="snackbarVisible"
      @close="closeSnackbar"
      :color="snackbarColor"
      :top="snackbarTop"
      >{{ snackbarText }}</snackbar
    >
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions, mapMutations } = createNamespacedHelpers(
  "folder"
);

// mixins
import { loadableProjectMixin } from "@/mixins/project/details/loadableProjectMixin";
import { projectNavigatable } from "@/mixins/shared/navigatable/projectNavigatable";
import { UPDATE_PROJECT_MANAGER } from "@/store/shared/mutationTypes/project/project-mutation-types";

export default {
  name: "FolderProject",
  inheritAttrs: false,
  mixins: [loadableProjectMixin, projectNavigatable],
  components: {
    ProjectOperationDialog: () =>
      import("@/components/project/dialogs/ProjectOperationDialog.vue")
  },
  computed: {
    ...mapState({ openProjects: "openProjects" })
  },
  methods: {
    ...mapMutations({ updateProjectManager: UPDATE_PROJECT_MANAGER }),
    ...mapActions({
      setOpenProject: "setOpenProject",
      deleteWorkflowProject: "deleteWorkflowProject",
      cancelWorkflowProject: "cancelWorkflowProject",
      suspendWorkflowProject: "suspendWorkflowProject",
      resumeWorkflowProject: "resumeWorkflowProject",
      assignManager: "assignManager"
    }),

    /**
     * perform Delete Project as a callback function
     * @return {Promise<void>}
     */
    async performProjectDelete() {
      await this.deleteWorkflowProject(this.projectId);
    },

    /**
     * perform Cancel Project as a callback function
     * @return {Promise<void>}
     */
    async performProjectCancel() {
      await this.cancelWorkflowProject(this.projectId);
    },

    /**
     * perform Suspend Project as a callback function
     * @param {String} suspensionMessage
     * @return {Promise<void>}
     */
    async performProjectSuspend(suspensionMessage) {
      const payload = {
        id: this.projectId,
        message: suspensionMessage
      };
      await this.suspendWorkflowProject(payload);
    },

    /**
     * perform Resume Project as a callback function
     * @return {Promise<void>}
     */
    async performProjectResume() {
      await this.resumeWorkflowProject(this.projectId);
    }
  }
};
</script>
